/* change this html background-color to same background-color as the main app 
to make scroll bounce the same color as page background-color */
html {
  background-color: #d6bcfa;
}
html,
body,
body > div,
.root {
  width: 100%;
  height: 100%;
}

.root {
  background-color: white;
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#chakra-toast-portal {
  height: 0;
}

#WEB3_CONNECT_MODAL_ID {
  height: 0;
}
